<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
const store = useStore();
const fullName = ref(
  store.state.$_user.PACIENTE_DATA.Nombres +
    " " +
    store.state.$_user.PACIENTE_DATA.Apellidos
);
</script>

<template>
  <div>
    <div class="card shadow-lg mb-4 glass">
      <div class="card-body p-3">
        <div class="row gx-4">
          <div class="col-auto">
            <div class="avatar avatar-xl position-relative">
              <img
                src="https://static.vecteezy.com/system/resources/previews/016/079/150/non_2x/user-profile-account-or-contacts-silhouette-icon-isolated-on-white-background-free-vector.jpg"
                alt="profile_image"
                class="shadow-sm w-100 border-radius-lg"
              />
            </div>
          </div>
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1">{{ fullName }}</h5>
              <p class="mb-0 font-weight-bold text-sm">Titular</p>
            </div>
          </div>
          <div
            class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.glass {
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4.5px);
  -webkit-backdrop-filter: blur(4.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
</style>
